<template>
  <div>
    <!-- 首页轮播 begin -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img
          src="@/assets/images/index/banner.jpg"
          alt=""
          style="width: 99.999%; overflow: hidden"
        />
      </van-swipe-item>
    </van-swipe>

    <!-- 待办事项BEGIN-->
    <div class="aui-flex aui-flex-mar">
      <div class="aui-flex-box">
        <h2>待办事项</h2>
      </div>
    </div>
    <div class="aui-palace clearfix">
      <router-link href="javascript:;" class="aui-palace-grid" to="istIndex">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/task.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>首页待办</h2>
        </div>
      </router-link>
    </div>
    <div class="divHeight"></div>
    <!-- 待办事项END-->

    <!-- 工作台BEGIN-->
    <div class="aui-flex aui-flex-mar">
      <div class="aui-flex-box">
        <h2>工作台</h2>
      </div>
    </div>
    <div class="aui-palace clearfix">
      <router-link href="javascript:;" class="aui-palace-grid" to="notice">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/notice.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>通知公告</h2>
        </div>
      </router-link>
      <router-link href="javascript:;" class="aui-palace-grid" to="shop">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/shop.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>店铺信息</h2>
        </div>
      </router-link>
      <router-link href="javascript:;" class="aui-palace-grid" to="approve">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/kaoqin.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>行政审批</h2>
        </div>
      </router-link>
      <a href="javascript:;" class="aui-palace-grid" @click="scanQR">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/task.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>门前三包</h2>
        </div>
      </a>
      <a href="javascript:;" class="aui-palace-grid" @click="userComplain">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/shopComplain.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>用户投诉</h2>
        </div>
      </a>
      <router-link
        href="javascript:;"
        class="aui-palace-grid"
        to="shopComplain"
      >
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/userComplain.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>商家投诉</h2>
        </div>
      </router-link>

      <router-link href="javascript:;" class="aui-palace-grid" to="adviseAdd">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/advise.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>劝&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;导</h2>
        </div>
      </router-link>
      <a href="javascript:;" class="aui-palace-grid" @click="scanAttendance">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/attendance.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>扫码考勤</h2>
        </div>
      </a>
      <router-link href="javascript:;" class="aui-palace-grid" to="event">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/event.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>平台事件</h2>
        </div>
      </router-link>
    </div>
    <div class="divHeight"></div>
    <!-- 工作台END-->

    <!-- 数据列表 begin -->
    <div v-show="isShow" class="aui-flex aui-flex-mar">
      <div class="aui-flex-box">
        <h2>数据列表</h2>
      </div>
    </div>
    <div v-show="isShow" class="aui-palace clearfix">
      <router-link href="javascript:;" class="aui-palace-grid" to="echarts">
        <div class="aui-palace-grid-icon">
          <img src="../../assets/images/index/kaoqin.jpg" alt="" />
        </div>
        <div class="aui-palace-grid-text">
          <h2>分析图表</h2>
        </div>
      </router-link>
    </div>
    <div class="divHeight"></div>
    <!-- 数据列表-->
  </div>
</template>

<script>
  import { checkRole } from '@/util/validate'
  import { Dialog } from 'vant'
  import Base64 from '@/util/Base64'
  import { Emas } from '@/assets/js/Emas'
  import store from '@/store'

  export default {
    name: 'Index',
    data() {
      return {
        isShow: false,
      }
    },
    beforeMount() {
      //领导或中队管理员可看到报表按钮
      this.isShow = checkRole(3) || checkRole(11)
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(staffName, userId, 'index', '城管-首页', 'zzdcg.yy.gov.cn/index')
    },

    methods: {
      //门前三包
      scanQR() {
        window.dd
          .scan({
            type: 'qr',
          })
          .then((res) => {
            let ID = res.text.split('=')[1]
            this.$router.push({
              path: '/scan',
              query: {
                street_ID: ID,
              },
            })
          })
          .catch()
      },

      //扫码考勤
      scanAttendance() {
        window.dd
          .scan({
            type: 'qr',
          })
          .then((res) => {
            let ID = res.text.split('=')[1]
            this.$router.push({
              path: '/attendance',
              query: {
                street_ID: ID,
              },
            })
          })
          .catch()
      },

      //用户投诉
      userComplain() {
        var url = ''
        if (checkRole(4) || checkRole(12)) {
          url = '/userComplain'
        } else if (checkRole(13)) {
          url = '/userComplainApparitorList'
        } else {
          Dialog({ message: '暂无查看权限！' })
          return
        }
        this.$router.push({
          path: url,
          query: {},
        })
      },
    },
  }
</script>

<style scoped>
  @import '../../assets/css/base.css';
  @import '../../assets/css/home.css';
  .tz {
    width: 44px;
    height: 44px;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
  .swiper-container {
    width: 100%;

    margin-left: auto;
    margin-right: auto;
  }
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  .gallery-top {
    height: 80%;
    width: 100%;
  }
  .gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
    height: 80px;
  }
  .gallery-thumbs .swiper-slide {
    width: 25%;
    opacity: 0.99;
  }
  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }
  .gallery-thumbs .swiper-slide {
    margin-right: 20px;
  }
  .gallery-thumbs .swiper-slide img {
    width: 100%;
  }

  .gallery-thumbs .swiper-wrapper {
    overflow: inherit;
  }

  #certify {
    width: 92%;
    height: auto;
    background: white;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 3px 9px #f2efef;
    -webkit-appearance: none;
    border-radius: 2px;
    overflow: hidden;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    position: relative;
  }

  #certify:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: -18px;
    left: -18px;
    height: 20px;
    width: 20px;
    background: #fff;
    z-index: 100002;
    border-radius: 5px 0 5px 0;
  }
  #certify:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: -18px;
    right: -18px;
    height: 20px;
    width: 20px;
    background: #fff;
    z-index: 100002;
    border-radius: 5px;
  }
  .swiper-wrapper {
    background: #fff;
    width: 100%;
    margin: 0;
    border-radius: 5px 5px 0 0;
    -webkit-appearance: none;
    border-radius: 3px;
    overflow: initial;
  }
  .swiper-slide {
    width: 103%;
    border-radius: 5px 5px 0 0;
    -webkit-appearance: none;
  }
  .swiper-slide p {
    padding: 0.25rem 0.3rem;
  }
  .swiper-slide img {
    border-radius: 0;
  }
  .ads img {
    width: 98%;
  }
</style>
